const theme = {
  colors: {
    primary100: "rgba(0, 55,119, 1)",
    primary90: "rgba(0, 55,119, 0.9)",
    primary80: "rgba(0, 55,119, 0,8)",
    primary70: "rgba(0, 55,119, 0.7)",
    primary60: "rgba(0, 55,119, 0.6)",
    primary50: "rgba(0, 55,119, 0.5)",
    primary40: "rgba(0, 55,119, 0.4)",
    primary30: "rgba(0, 55,119, 0.3)",
    primary20: "rgba(0,55,119, 0.2)",
    primary10: "rgba(0,55,119, 0.1)",

    secondary100: "rgba(238, 27, 46, 1)",
    secondary90: "rgba(238, 27, 46, 0.9)",
    secondary80: "rgba(238, 27, 46, 0.8)",
    secondary70: "rgba(238, 27, 46, 0.7)",
    secondary60: "rgba(238, 27, 46, 0.6)",
    secondary50: "rgba(238, 27, 46, 0.5)",
    secondary40: "rgba(238, 27, 46, 0.4)",
    secondary30: "rgba(238, 27, 46, 0.3)",
    secondary20: "rgba(238, 27, 46, 0.2)",
    secondary10: "rgba(238, 27, 46, 0.1)",

    white100: "rgba(255, 255, 255, 1)",
    white90: "rgba(255, 255, 255, 0.9)",
    white80: "rgba(255, 255, 255, 0.8)",
    white70: "rgba(255, 255, 255, 0.7)",
    white60: "rgba(255, 255, 255, 0.6)",
    white50: "rgba(255, 255, 255, 0.5)",
    white40: "rgba(255, 255, 255, 0.4)",
    white30: "rgba(255, 255, 255, 0.3)",
    white20: "rgba(255, 255, 255, 0.2)",
    white10: "rgba(255, 255, 255, 0.1)",

    grey100: "rgba(20, 20, 20, 1)",
    grey90: "rgba(46, 46, 46, 1)",
    grey80: "rgba(71, 71, 71, 1)",
    grey70: "rgba(97, 97, 97, 1)",
    grey60: "rgba(122, 122, 122, 1)",
    grey50: "rgba(148, 148, 148, 1)",
    grey40: "rgba(173, 173, 173, 1)",
    grey30: "rgba(199, 199, 199, 1)",
    grey20: "rgba(224, 224, 224, 1)",
    grey15: "rgba(224, 224, 224, 0.5)",
    grey10: "rgba(250, 250, 250, 1)",

    success: "rgba(126, 211, 33, 1)",
    error: "rgba(227, 47, 69, 1)",
  },

  spacing: {
    x0: "0px",
    x1: "4px",
    x2: "8px",
    x3: "12px",
    x4: "16px",
    x5: "20px",
    x6: "24px",
    x7: "28px",
    x8: "32px",
    x9: "36px",
    x10: "40px",
    x11: "44px",
    x12: "48px",
    x13: "52px",
    x14: "56px",
    x15: "60px",
    x16: "64px",
    x32: "128px",
    x64: "256px",
    x128: "512px",
  },

  radii: {
    x1: 4,
    x2: 8,
    x3: 12,
    x4: 16,
  },

  breakpoints: {
    notSmall: "min-width: 30em",
    mediumAndUp: "min-width: 60em",
    medium: "min-width: 30em and max-width: 60em",
    large: "min-width: 60em",
  },

  fontScale: {
    x1: "12px",
    x2: "14px",
    x3: "16px",
    x4: "20px",
    x5: "24px",
    x5s: "30px",
    x6: "36px",
    x7: "48px",
    x10: "60px",
  },

  fontWeight: {
    light: 300,
    normal: 400,
    semibold: 600,
    bold: 700,
  },
};

export default theme;
