import React from "react";
import styled from "styled-components";

import { theme } from "../config";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: ${theme.spacing.x3} ${theme.spacing.x4};
`;

const Layout = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Layout;
