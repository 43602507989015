import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { HStack, Spacer, Text } from "../components";
import { Chevron } from "../assets";
import { theme, envVars } from "../config";
import { useResource } from "../hooks";

const OutterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
  }
`;

const TableContainer = styled.div`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 2px solid ${theme.colors.grey15};
`;

const ListItemContainer = styled.tr`
  box-sizing: border-box;
  width: 100%;
  padding: ${theme.spacing.x2} ${theme.spacing.x2};
  background: ${({ idx }) =>
    idx % 2 === 0 ? theme.colors.white100 : theme.colors.grey15};
  &:hover {
    background: ${({ isHeader }) =>
    isHeader ? theme.colors.grey15 : theme.colors.secondary20};
  }
`;

const Column = styled.td`
  display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "block")};
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
  @media (${theme.breakpoints.mediumAndUp}) {
    display: block;
  }
`;

const Header = styled.th`
  display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "block")};
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
  font-size: ${theme.spacing.x4};
  color: ${theme.colors.secondary100};
  text-align: ${({ align }) => (align ? align : "left")};
  font-weight: ${theme.fontWeight.bold};
  @media (${theme.breakpoints.mediumAndUp}) {
    font-size: ${theme.spacing.x5};
    display: block;
  }
`;

const Container = styled.table`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-top: ${theme.spacing.x4};
  flex-direction: column;
  @media (${theme.breakpoints.mediumAndUp}) {
    margin-top: 0;
  }
`;

const Td = styled.td`
  border: 2px solid ${({ idx }) =>
    idx % 2 === 0 ? theme.colors.white100 : theme.colors.grey15};
  padding: 2px 2px;
  @media (${theme.breakpoints.mediumAndUp}) {
    padding: 10px 10px;
  }
`;

const Th = styled.td`
  border: 2px solid ${({ idx }) =>
    idx % 2 === 0 ? theme.colors.white100 : theme.colors.grey15};
  padding: 2px 2px;
  @media (${theme.breakpoints.mediumAndUp}) {
    padding: 10px 10px;
    ${({ width }) => width && `width: ${width};`
  }
`;

const Tr = styled.tr`
  border: 2px solid ${({ idx }) =>
    idx % 2 === 0 ? theme.colors.white100 : theme.colors.grey15};
`;

const Table = styled.table`
  border: 2px solid ${({ idx }) =>
    idx % 2 === 0 ? theme.colors.white100 : theme.colors.grey15};
  border-collapse: collapse;
  width: 98%;
  margin: 2px;
  background: ${({ idx }) =>
    idx % 2 === 0 ? theme.colors.grey15 : theme.colors.white100};
  @media (${theme.breakpoints.mediumAndUp}) {
    margin: 10px;
  }  
`;

const ListItem = ({ item, idx }) => {
  item = item.attributes
  const [accordionOpen, setAccordionOpen] = useState(false);
  return (
    <ListItemContainer idx={idx}>
      <Spacer height="x2" />
      <HStack align="center" justify="space-between">
        <Column width={"5%"}>
          <Text color="grey100" size="x4" weight="bold">
            {`${item.powerAverageRank}.`}
          </Text>
        </Column>
        <Column width={"5%"}>
          <img
            src={`https://data.fei.org/Content/Images/Flags/${item.nf}.png`}
            alt={`Flag: ${item.nf}`}
          />
        </Column>
        <Column width={"30%"}>
          <Text color="grey100" size="x4" mobileSize="x2" weight="semibold">
            {item.athlete}
          </Text>
        </Column>
        <Column width={"30%"}>
          <Text color="grey80" size="x4" mobileSize="x1" weight="semibold">
            {item.horse}
          </Text>
        </Column>
        <Column width={"20%"}>
          <Text align="right" size="x4" weight="bold" color="grey100">
            {item.powerAverage}
          </Text>
        </Column>
        <Column width={"5%"} onClick={() => setAccordionOpen(!accordionOpen)}>
          <Chevron
            direction={accordionOpen ? "top" : "bottom"}
            fill={theme.colors.secondary100}
          />
        </Column>
      </HStack>
      <Spacer height="x2" />
      {accordionOpen && (
        <>
          <Spacer height="x4" />
          <Table idx={idx}>
            <Tr idx={idx}>
              <Th idx={idx} width={"90px"}>
                <Text align="left" size="x3" weight="bold" color="grey100">
                  Date
                </Text>
              </Th>
              <Th idx={idx}>
                <Text align="left" size="x3" weight="bold" color="grey100">
                  Show
                </Text>
              </Th>
              <Th idx={idx} width={"50px"}>
                <Text align="right" size="x3" weight="bold" color="grey100">
                  Score
                </Text>
              </Th>
            </Tr>
            {item.results.map((item) => {
              return (
                <Tr idx={idx}>
                  <Td idx={idx}>
                    <Text align="left" size="x3" weight="bold" color="grey100">
                      {item.date}
                    </Text>
                  </Td>
                  <Td idx={idx}>
                    <Text align="left" size="x3" weight="bold" color="grey100">
                      {item.show}
                    </Text>
                  </Td>
                  <Td idx={idx}>
                    <Text align="right" size="x3" weight="bold" color="grey100">
                      {item.score}
                    </Text>
                  </Td>
                </Tr>
              );
            })}
          </Table>
          <Spacer height="x2" />
        </>
      )}
    </ListItemContainer >
  );
};

const RegionalPwrAvgFull = ({ region, classLevel }) => {
  const regionTitle = envVars.powerAverageRegionTitles[region.toLowerCase()] || "Regional";
  const { classLevelTitle, classLevelParam, dateFromParam, dateToParam } = envVars.powerAverageClassLevelParams[classLevel.toLowerCase()];

  const [isLoading, setIsLoading] = useState(true);
  const [showMoreText, setShowMoreText] = useState(false);

  let url = `provider/${envVars.providerId}/youth_regional_power_average` +
    `?athlete_region=${region}` +
    `&return_top=25` +
    `&one_in_region_required=false` +
    `&athlete_max_age=18` +
    dateFromParam +
    dateToParam +
    classLevelParam

  const { getAll: getAllData, all: data } = useResource(url);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getAllData();
      setIsLoading(false);
    };

    window.scrollTo(0, 0);
    fetchData();
  }, [getAllData]);

  return (
    <>
      <Text size="x6" weight="semibold">
        {regionTitle} Points League {classLevelTitle}
      </Text>
      <Spacer height="x6" />
      <Text size="x5" weight="normal">
        <b>Power Average:</b> Calculated from the best three finishing scores for the combination,
        achieved across any of the qualifying classes contested. Two of the three counting scores
        are a combination's best two finishing scores from the current calendar year. The third
        qualifying score depends on where the first two were achieved. At least one counting
        score must be achieved within the member's designated region.
      </Text>
      {showMoreText && (
        <>
          <Spacer height="x2" />
          <Text size="x4" weight="normal">
            The calculation for the power average is made in this order:
            <ol>
              <li>
                First, a combination's lowest two finishing scores from the current calendar
                year are found, regardless of the region.
                <Spacer height="x2" />
              </li>
              <li>
                Identifying the third finishing score then depends on the region in which the
                lowest two 'calendar year' finishing scores were achieved in:
                <Spacer height="x2" />
                <ol type="a">
                  <li>
                    If at least one of these two finishing scores was achieved in the athlete's
                    region, then the best finishing score from the qualification period (not
                    already considered) will be used as the third qualifying finishing score.
                    <Spacer height="x2" />
                  </li>
                  <li>
                    If neither of the two finishing scores were achieved in the athlete's region,
                    the best finishing score in the qualification period that was in the athlete's
                    region will be used.
                  </li>
                </ol>
              </li>
            </ol>
          </Text>
        </>
      )}
      <Spacer height="x4" />
      <Text
        onClick={() => setShowMoreText(!showMoreText)}
        size="x4"
        weight="bold"
        style={{ cursor: "pointer" }}
      >
        {showMoreText ? "Show less" : "Read more"}
      </Text>
      <Spacer height="x6" />
      <Wrapper>
        <OutterWrapper>
          <Container>
            <TableContainer>
              <ListItemContainer isHeader={true}>
                <Spacer height="x2" />
                <HStack align="center" justify="space-between">
                  <Header width="5%"> </Header>
                  <Header width="5%"> </Header>
                  <Header width="30%">Rider</Header>
                  <Header width="30%">Horse</Header>
                  <Header width="20%" align="right">
                    Total Points
                  </Header>
                </HStack>
                <Spacer height="x2" />
              </ListItemContainer>
              {isLoading
                ? (
                  <ListItemContainer idx="0">
                    <Text size="x4" weight="semibold">
                      Loading...
                    </Text>
                  </ListItemContainer>

                )
                : (
                  data && data.data.length > 0
                    ? data.data.map((item, idx) => {
                      return <ListItem key={idx} item={item} idx={idx} />;
                    })
                    : <ListItemContainer idx="0">
                      <Text size="x4" weight="semibold">
                        No qualifiers. Check back later.
                      </Text>
                    </ListItemContainer>
                )}
            </TableContainer>
          </Container>
        </OutterWrapper>
        <Spacer height="x4" />
      </Wrapper>
    </>
  );
};

export default RegionalPwrAvgFull;
