import React from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";

import { theme } from "../config";
import Text from "./Text";
import VStack from "./VStack";
import HStack from "./HStack";
import Spacer from "./Spacer";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-top: ${theme.spacing.x4};
  flex-direction: column;
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 23.5%;
    margin-top: 0;
  }
`;

const Divider = styled.div`
  height: 3px;
  width: 100%;
  background: ${theme.colors.primary100};
  @media (${theme.breakpoints.mediumAndUp}) {
    height: 50px;
  }
`;

const ListItemContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: ${theme.spacing.x2} ${theme.spacing.x2};
  background: ${({ idx }) =>
    idx % 2 === 0 ? theme.colors.white100 : theme.colors.grey20};
`;

const Column = styled.div`
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
`;

const ListItem = ({ item, idx, groupBy }) => {
  if (item.rank === 1 && groupBy !== "horse") {
    const splitName = item.groupLabel.split(" ");
    return (
      <ListItemContainer idx={idx}>
        <HStack align="center">
          <Column width={"10%"}>
            <Text size="x2" weight="bold">{`${item.rank}.`}</Text>
          </Column>
          <Column width="70%">
            <VStack>
              <HStack align="center">
                <Text
                  size={splitName.length === 1 ? "x4" : "x2"}
                  weight={splitName.length === 1 ? "bold" : "normal"}
                  margin={"none"}
                >
                  {splitName[0]}
                </Text>
                <Spacer width="x4" />
                <img
                  src={`https://data.fei.org/Content/Images/Flags/${item.groupValue}.png`}
                  alt={`Flag: ${item.groupValue}`}
                />
              </HStack>
              <HStack align="center">
                {splitName.map(
                  (name, idx) =>
                    idx !== 0 && (
                      <>
                        <Text size="x4" margin={"none"} weight="bold">
                          {name}
                        </Text>
                        <Spacer width="x1" />
                      </>
                    )
                )}
              </HStack>
            </VStack>
          </Column>
          <Column width={"20%"}>
            <Text size="x4" weight="bold" align="right">
              {item.metricValue}
            </Text>
          </Column>
        </HStack>
      </ListItemContainer>
    );
  } else {
    return (
      <ListItemContainer idx={idx}>
        <HStack align="center" justify="space-between">
          <HStack align="center">
            <Column width={"10%"}>
              <Text color="grey100" size="x2" weight="bold">{`${
                item.rank
              }.`}</Text>
            </Column>
            <Column width={"10%"}>
              <img
                src={`https://data.fei.org/Content/Images/Flags/${item.groupValue}.png`}
                alt={`Flag: ${item.groupValue}`}
              />
            </Column>
            <Column width={"60%"}>
              <Text color="grey100" size="x2" weight="semibold">
                {item.groupLabel}
              </Text>
            </Column>
          </HStack>
          <Column width={"20%"}>
            <Text color="grey100" size="x3" weight="bold" align="right">
              {item.metricValue}
            </Text>
          </Column>
        </HStack>
      </ListItemContainer>
    );
  }
};

const StatList = ({
  title,
  list,
  showSeeMore = true,
  selectedLevels,
  selectedYears,
  disableYearDD = false,
  groupBy
}) => {
  const metricType = () => {
    switch (title) {
      case "Runs":
        return { title: title, metric: "run" };
      case "Wins":
        return { title: title, metric: "win" };
      case "Most XCT Clears":
        return { title: title, metric: "xct_clear" };
      case "Most XCJ Clears":
        return { title: title, metric: "xcj_clear" };
      case "Lowest Dressage PB":
        return { title: title, metric: "dr_score_pb" };
      case "Lowest Finishing PB":
        return { title: title, metric: "final_score_pb" };
      case "Highest number of FOD's":
        return { title: title, metric: "fod" };
      case "Highest SJ Clears":
        return { title: title, metric: "sj_clear" };
      default:
        return { title: title, metric: "run" };
    }
  };
  return (
    <Container>
      <VStack>
        <Text size="x5" weight="semibold">
          {title}
        </Text>
        <Spacer height="x1" />
        <Divider />
        {list.map((item, idx) => {
          return <ListItem key={idx} item={item} idx={idx} groupBy={groupBy}/>;
        })}
      </VStack>
      {showSeeMore && (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/analysis`, {
              state: {
                metric: metricType(),
                selectedLevels: selectedLevels,
                selectedYears: selectedYears,
                disableYearDD: disableYearDD,
              },
            })
          }
        >
          <HStack justify="flex-end">
            <Text size="x4" weight="semibold">
              See More
            </Text>
          </HStack>
        </div>
      )}
    </Container>
  );
};

export default StatList;
