import React, { useEffect, useState } from "react";
import Navigation from "./Navigation";
import { Header, Sidebar, Layout } from "./components";
import { Location } from "@reach/router";

function App() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [activeRoute, setActiveRoute] = useState(0);

  var x = window.matchMedia("(min-width: 60em)");
  useEffect(() => {
    myFunction(x); // Call listener function at run time
    x.addListener(myFunction); // Attach listener function on state changes
    function myFunction(x) {
      if (x.matches) {
        setShowSidebar(false);
      }
    }
  }, [x]);

  return (
    <Location>
      {({ location }) => {
          return (
            <>
              <Header
                setShowSidebar={setShowSidebar}
                showSidebar={showSidebar}
                activeRoute={activeRoute}
                setActiveRoute={setActiveRoute}
              />
              <div style={{ display: "flex" }}>
                <Sidebar
                  setShowSidebar={setShowSidebar}
                  showSidebar={showSidebar}
                  activeRoute={activeRoute}
                  setActiveRoute={setActiveRoute}
                />
                <Layout>
                  <Navigation />
                </Layout>
              </div>
            </>
          );
        }
      }
    </Location>
  );
}

export default App;
