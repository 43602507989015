import React from "react";
const directionToAngle = (direction) => {
  switch (direction) {
    case "right":
      return 90;
    case "top":
      return 180;
    case "left":
      return 270;
    default:
      return 0;
  }
};

const Chevron = ({ direction = "bottom", fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 16 16"
    transform={`rotate(${directionToAngle(direction)} 0 0)`}
  >
    <path
      fill={fill}
      d="M4.14645,5.64645 C4.34171,5.45118 4.65829,5.45118 4.85355,5.64645 L7.9999975,8.79289 L11.1464,5.64645 C11.3417,5.45118 11.6583,5.45118 11.8536,5.64645 C12.0488,5.84171 12.0488,6.15829 11.8536,6.35355 L8.35355,9.85355 C8.15829,10.0488 7.84171,10.0488 7.64645,9.85355 L4.14645,6.35355 C3.95118,6.15829 3.95118,5.84171 4.14645,5.64645 Z"
    />
  </svg>
);

export default Chevron;
