import React from "react";
import { Text, Spacer } from "../components";
import LeaguePrizeTable from "../components/LeaguePrizeTable";

const AboutLeagues = () => {
  return (
    <>
      <Text size="x6" weight="bold">
        About the Leagues
      </Text>
      <Spacer height="x4" />
      <Text size="x4" weight="normal">
        The search is on to find the most consistent grassroots combination
        within the EquiRatings British Eventing Grassroots Leagues
      </Text>
      <Spacer height="x2" />
      <Text size="x4" weight="normal">
        Riders competing across BE80, BE90 and BE100 will now have their own
        <b>Opposition Beaten Percentage Leagues (OBP Leagues)</b> across the
        three BE levels. In addition to this new members have their very own{" "}
        <b>Newcomers League</b> which spans their first and second season
        competing across the three combined categories. A new member is defined
        as someone who has either joined for the first time or whose membership
        has been lapsed for a period of a minimum of three years.
      </Text>
      <Spacer height="x2" />
      <Text size="x4" weight="normal">
        <b>The OBP Leagues</b> have been developed by EquiRatings and are
        designed to highlight the most consistent combinations each season. The
        OBP is a single number that represents how many combinations you will
        have competed against in a season and the percentage of them that you
        finished ahead of.
      </Text>
      <Spacer height="x2" />
      <Text size="x4" weight="normal">
        Wins and top placings are of course great and the recognition that comes
        with that is fantastic. However, we also know that finishing well,
        within a strong field of competitors, is definitely something to be
        proud of also and should be celebrated; the new OBP Leagues will do
        exactly this.
      </Text>
      <Spacer height="x2" />
      <Text size="x4" weight="normal">
        The four leagues will also have a <b>£10,000 prize money</b> pot put
        behind them. Every other month, commencing 1 April until 31 October a{" "}
        <b>Performance Prize Money</b> award will be given to the highest based
        combination in each league. You can only win the Performance Bonus once
        within each league as it will go down the line if the current leader has
        won it already in the season.{" "}
      </Text>
      <Spacer height="x2" />
      <Text size="x4" weight="normal">
        The Performance Bonuses will be paid for the periods of March – April,
        May – June, July – August, Sept – October and will be paid the month
        following the period of which they cover. For ease of calculation the
        period will end on the first Tuesday of each month following the
        two-month period.
      </Text>
      <Spacer height="x2" />
      <Text size="x4" weight="normal">
        This means the first prize money pay out will be made in just a few
        weeks’ time for the March - April period! In addition to the bi-monthly
        prize money there will be overall league winner prize money award to the
        Top three in each of the leagues. This means that if the overall winner
        of the BE100 league was also a recipient of a
        <b>performance prize payout and a ‘3 wins’ prize money</b> payout then
        they could be taking home £1550 at the end of the season! A breakdown of
        the prize money on offer is as follows:
      </Text>
      <Spacer height="x2" />
      <LeaguePrizeTable />
      <Spacer height="x2" />
      <Text size="x4" weight="normal">
        Total Cash Bonus Pot of <b>£10,000</b>
      </Text>
    </>
  );
};
export default AboutLeagues;
