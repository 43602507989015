import styled from 'styled-components'

const VStack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  flex-grow: ${({ grow }) => grow};
`

VStack.defaultProps = {
  align: 'flex-start',
  justify: 'flex-start',
  grow: 1,
}

export default VStack