import React from "react";
import styled from "styled-components";
import { theme } from "../config";
const Td = styled.td`
  border: 1px solid black;
`;
const Th = styled.td`
  border: 1px solid black;
  font-weight: 600;
  width: 20%;
`;
const Tr = styled.tr`
  border: 1px solid black;
`;

const Table = styled.table`
  border-spacing: 0;
  border: 1px solid black;
  width: 100%;
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 55%;
  }
`;

const LeaguePrizeTable = () => {
  return (
    <>
      <Table>
        <tbody>
          <Tr>
            <Th></Th>
            <Th>Newcomers</Th>
            <Th>BE80</Th>
            <Th>BE90</Th>
            <Th>BE100</Th>
          </Tr>
          <Tr>
            <Td>Performance Prize Money x 4</Td>
            <Td style={{ textAlign: "center" }}>£200</Td>
            <Td style={{ textAlign: "center" }}>£200</Td>
            <Td style={{ textAlign: "center" }}>£250</Td>
            <Td style={{ textAlign: "center" }}>£300</Td>
          </Tr>
          <Tr>
            <Td>Overall League Prize Money</Td>
            <Td style={{ textAlign: "center" }}>-</Td>
            <Td style={{ textAlign: "center" }}>-</Td>
            <Td style={{ textAlign: "center" }}>-</Td>
            <Td style={{ textAlign: "center" }}>-</Td>
          </Tr>
          <Tr>
            <Td>1st</Td>
            <Td style={{ textAlign: "center" }}>£500</Td>
            <Td style={{ textAlign: "center" }}>£500</Td>
            <Td style={{ textAlign: "center" }}>£700</Td>
            <Td style={{ textAlign: "center" }}>*£1000</Td>
          </Tr>
          <Tr>
            <Td>2nd</Td>
            <Td style={{ textAlign: "center" }}>£350</Td>
            <Td style={{ textAlign: "center" }}>£350</Td>
            <Td style={{ textAlign: "center" }}>£500</Td>
            <Td style={{ textAlign: "center" }}>£750</Td>
          </Tr>
          <Tr>
            <Td>3rd</Td>
            <Td style={{ textAlign: "center" }}>£250</Td>
            <Td style={{ textAlign: "center" }}>£250</Td>
            <Td style={{ textAlign: "center" }}>£300</Td>
            <Td style={{ textAlign: "center" }}>£500</Td>
          </Tr>
        </tbody>
      </Table>
      <p>
        * £250 bonus paid to the winner of the BE100 League if they have had 3
        BE100 wins in the current season.
      </p>
    </>
  );
};

export default LeaguePrizeTable;
