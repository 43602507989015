import { envVars } from "../config";

const addSelectedLevelIfNew = (selectedLevels, incomingLevel) => {
  const newSelectedLevels = [];
  const existingLevels = new Map();

  for (const level of [...selectedLevels, incomingLevel]) {
      if(!existingLevels.has(level.level)){
          existingLevels.set(level.level, true);    // set any value to Map
          newSelectedLevels.push(level);
      }
  }

  if (newSelectedLevels.length === envVars.allClassLevels.length) {
    return envVars.allClassLevels;
  } else {
    return newSelectedLevels;
  }
};

const allLevelsSelected = (selectedLevels) => {
  return selectedLevels.length === envVars.allClassLevels.length
};

const buildYearParams = (selectedYears) => {
  let yearParams = "";

  selectedYears.forEach((year) => {
    yearParams = yearParams + `&years[]=${year}`;
  });

  return yearParams;
};

const buildLevelParams = (selectedLevels) => {
  let levelParams = "";

  if (allLevelsSelected(selectedLevels)) {
    selectedLevels.forEach((level) => {
      levelParams = levelParams + `&er_levels[]=${level}`;
    });
  } else {
    selectedLevels.forEach((level) => {
      levelParams = levelParams + `&er_levels[]=${level.level}`;
    });
  }

  return levelParams;
};

const levelItemIsChecked = (selectedLevels, item) => {
  return (
    selectedLevels
    .map((lvl) => lvl.level)
    .includes(item.level)
  ) || (
    item.title === "All Levels"
    && allLevelsSelected(selectedLevels)
  )
};

export default {
  addSelectedLevelIfNew,
  allLevelsSelected,
  buildYearParams,
  buildLevelParams,
  levelItemIsChecked
};