import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { HStack, Spacer, Text, VStack } from "../components";
import { theme, envVars } from "../config";
import { useResource } from "../hooks";

const OutterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
    width: 70%;
  }
`;

const Padding = styled.div`
  padding: ${theme.spacing.x2} 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
  }
`;

const TableContainer = styled.div`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 2px solid ${theme.colors.grey15};
`;

const ListItemContainer = styled.tr`
  box-sizing: border-box;
  width: 100%;
  padding: ${theme.spacing.x2} ${theme.spacing.x2};
  background: ${({ idx }) =>
    idx % 2 === 0 ? theme.colors.white100 : theme.colors.grey15};
  &:hover {
    background: ${({ isHeader }) =>
    isHeader ? theme.colors.grey15 : theme.colors.secondary20};
  }
`;

const Column = styled.td`
  display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "block")};
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
  @media (${theme.breakpoints.mediumAndUp}) {
    display: block;
  }
`;

const Header = styled.th`
  display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "block")};
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
  font-size: ${theme.spacing.x3};
  color: ${theme.colors.secondary100};
  text-align: ${({ align }) => (align ? align : "left")};
  font-weight: ${theme.fontWeight.bold};
  @media (${theme.breakpoints.mediumAndUp}) {
    font-size: ${theme.spacing.x4};
    display: block;
  }
`;

const Container = styled.table`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-top: ${theme.spacing.x4};
  flex-direction: column;
  @media (${theme.breakpoints.mediumAndUp}) {
    margin-top: 0;
  }
`;

const AdvertContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 29%;
  }
`;

const Advert = styled.img`
  display: flex;
  background: ${theme.colors.secondary50};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 2px solid ${theme.colors.grey15};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  max-width: 100%;
`;

const ListItem = ({ item, idx }) => {
  return (
    <ListItemContainer idx={idx}>
      <Spacer height="x2" />
      <HStack align="center" justify="space-between">
        <Column width={"5%"}>
          <Text
            color="grey100"
            size="x3"
            weight="bold"
          >{`${item.attributes.rank}.`}</Text>
        </Column>
        <Column width={"5%"}>
          <img
            src={`https://data.fei.org/Content/Images/Flags/${item.attributes.athleteNationality}.png`}
            alt={`Flag: ${item.attributes.athleteNationality}`}
          />
        </Column>
        <Column width={"30%"}>
          <Text color="grey100" size="x4" mobileSize="x2" weight="semibold">
            {item.attributes.athlete}
          </Text>
          <Text color="grey80" size="x3" mobileSize="x1" weight="semibold">
            {item.attributes.horse}
          </Text>
        </Column>
        <HStack width="40%">
          <Column width={"25%"}>
            <Text size="x4" weight="normal" align="left" color="grey100">
              {item.attributes.rank1Points || "-"}
            </Text>
          </Column>
          <Column width={"25%"}>
            <Text size="x4" weight="normal" align="left" color="grey100">
              {item.attributes.rank2Points || "-"}
            </Text>
          </Column>
          <Column width={"25%"}>
            <Text size="x4" weight="normal" align="left" color="grey100">
              {item.attributes.rank3Points || "-"}
            </Text>
          </Column>
          <Column width={"25%"}>
            <Text size="x4" weight="normal" align="left" color="grey100">
              {item.attributes.rank4Points || "-"}
            </Text>
          </Column>
        </HStack>
        <Column width={"10%"} hideOnMobile>
          <Text size="x4" weight="normal" align="right" color="grey100">
            {item.attributes.points || "-"}
          </Text>
        </Column>
        <Column width={"10%"} hideOnMobile>
          <Text size="x4" weight="normal" align="right" color="grey100">
            {item.attributes.lostPoints === 0
              ? 0
              : item.attributes.lostPoints || "-"}
          </Text>
        </Column>
      </HStack>
      <Spacer height="x2" />
    </ListItemContainer>
  );
};

const BannerImage = styled.img`
  display: none;
  cursor: pointer;
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 100%;
    display: flex;
    margin-bottom: ${theme.spacing.x6};
    background: ${theme.colors.secondary50};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 2px solid ${theme.colors.grey15};
    align-items: center;
    justify-content: center;
  }
`;

const Image = styled.img`
  height: 50px;
  @media (${theme.breakpoints.mediumAndUp}) {
    height: 70px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  @media (${theme.breakpoints.mediumAndUp}) {
    align-items: center;
  }
`;

const EHOALeague = ({ sponsor, img }, ...props) => {
  const [showMoreText, setShowMoreText] = useState(false);
  const [obpIndex, setObpIndex] = useState(10);

  const { getAll: getAllEHOA, all: data } = useResource(
    `gbr_points_league?date_from=${envVars.currentYear}-01-01`
  );

  useEffect(() => {
    getAllEHOA();
  }, [getAllEHOA]);

  return data
    ? (
      <>
        <HStack align="center" justify="space-between">
          <Text size="x6" weight="semibold">
            EHOA Members League
          </Text>
          {sponsor && (
            <ImageContainer>
              <Image alt={sponsor} src={require(`../assets/${img}`)} />
            </ImageContainer>
          )}
        </HStack>
        <Spacer height="x6" />
        <Text size="x4" weight="normal">
          The Event Horse Owners Association (EHOA), a strategic partner of
          British Eventing, runs an owner-focused league for top level eventing
          on British soil. The league, supported by ESA, is designed to reward the most competitive
          and consistent combinations at CCI4*-S and CCI4*-L levels throughout
          the {envVars.currentYear} season. The EHOA points system, designed by
          EquiRatings, features a tight points distribution for the top 25
          (CCI4*-L) and top 15 (CCI4*-S) finishers, plus a unique negative points
          structure for cross-country jumping errors. Based on the first year
          (2022), it will remain hotly contested by a large number of both
          established and up-and-coming horses until the final leg, with the
          potential for dramatic changes to the series leader board on every
          cross-country day. The points system rewards consistency at this level
          and is a horse-based calculation – ensuring that it will be equally open
          to riders and owners, regardless of the number of horses they have at
          this level. The EHOA aims to give owners and riders of top-level horses
          a season to celebrate.
        </Text>
        {showMoreText && (
          <>
            <Spacer height="x2" />
            <Text size="x4" weight="normal">
              <b>Prize</b>: The EHOA Members’ League {envVars.currentYear} will be
              awarded a specially hand-crafted trophy and will also release
              significant prize money during the season.
              The EHOA Members’ League is open to all but in order to claim the prizes
              the owners of the horses must be a member of the EHOA before 1 September
              {envVars.currentYear}. For horses with multiple owners one owner must be
              a member. After that date, the league will reduce to only those
              combinations owned by an EHOA registered owner.
            </Text>
            <Spacer height="x2" />
            <Text size="x4" weight="normal">
              To learn more about the EHOA Members’ {envVars.currentYear} League, keep
              an eye on the EHOA website and social channels. We will also be bringing
              you more special EHOA Member League episodes of EquiRatings Eventing Podcast.
            </Text>
            <Spacer height="x4" />
            <Text size="x5" weight="bold">
              The {envVars.currentYear} Season:
            </Text>
            <Spacer height="x2" />
            <Text size="x2" weight="normal">
              Comprises all GBR CCI4*-S and CCI4*-L class from Thorseby onwards to the
              end of {envVars.currentYear}.
            </Text>
            <Spacer height="x4" />
            <Text size="x5" weight="bold">
              The Points:
            </Text>
            <Spacer height="x2" />
            <Text size="x2" weight="normal">
              <b>25pts</b> for winning a CCI4*-L, reducing by 1pt per place to
              all top 25 finishers
            </Text>
            <Spacer height="x1" />
            <Text size="x2" weight="normal">
              <b>15pts</b> for winning a CCI4*-S, reducing by 1pt per place to
              all top 15 finishers
            </Text>
            <Spacer height="x1" />
            <Text size="x2" weight="normal">
              <b>-5pts</b> for 20 to less than 40 jumping penalties on
              cross-country
            </Text>
            <Spacer height="x1" />
            <Text size="x2" weight="normal">
              <b>-10pts</b> for 40 or more jumping penalties on cross-country
            </Text>
            <Spacer height="x1" />
            <Text size="x2" weight="normal">
              <b>-15pts</b> for failing to finish the cross-country phase
            </Text>
            <Spacer height="x4" />
            <Text size="x5" weight="bold">
              The Calculation
            </Text>
            <Spacer height="x2" />
            <Text size="x2" weight="normal">
              A horse’s best four points values will be summed together ·{" "}
              <b>ALL</b> negative points incurred will be subtracted from
              their series total
            </Text>
            <Spacer height="x4" />
            <Text size="x2" weight="bold">
              For full rules of the EHOA Members’ League please visit the EHOA
              website.
            </Text>
          </>
        )}

        <Spacer height="x2" />
        <Text
          onClick={() => setShowMoreText(!showMoreText)}
          size="x4"
          weight="bold"
          style={{ cursor: "pointer" }}
        >
          {showMoreText ? "Show less" : "Read more"}
        </Text>

        <Spacer height="x6" />
        <BannerImage
          src={require("../assets/ehoaBanner.png")}
          width="100%"
          onClick={() =>
            window.open("https://ehoa.org/membership/join-today/")
          }
        />
        <Wrapper>
          <OutterWrapper>
            <Container>
              <TableContainer>
                <ListItemContainer isHeader={true}>
                  <Spacer height="x2" />
                  <HStack align="flex-end" justify="space-between">
                    <Header width="2.5%" />
                    <Header width="7.5%">Nation</Header>
                    <Header width="30%">Competitor</Header>
                    <VStack width="40%">
                      <Header width="100%" align="left" hideOnMobile>
                        Points achieved in top 4 runs
                        <Spacer height="x2" />
                        <HStack>
                          <Header width="25%" align="left" hideOnMobile>
                            <Text size="x3" color="secondary50" weight="bold">
                              R1
                            </Text>
                          </Header>
                          <Header width="25%" align="left" hideOnMobile>
                            <Text size="x3" color="secondary50" weight="bold">
                              R2
                            </Text>
                          </Header>
                          <Header width="25%" align="left" hideOnMobile>
                            <Text size="x3" color="secondary50" weight="bold">
                              R3
                            </Text>
                          </Header>
                          <Header width="25%" align="left" hideOnMobile>
                            <Text size="x3" color="secondary50" weight="bold">
                              R4
                            </Text>
                          </Header>
                        </HStack>
                      </Header>
                    </VStack>
                    <Header width="10%" align="right">
                      Total Points
                    </Header>
                    <Header width="10%" align="right">
                      Lost Points
                    </Header>
                  </HStack>
                  <Spacer height="x2" />
                </ListItemContainer>
                {data.data.length > 0 ? (
                  data.data.slice(0, obpIndex).map((item, idx) => {
                    return <ListItem key={idx} item={item} idx={idx} />;
                  })
                ) : (
                  <Padding>
                    <Text
                      size="x3"
                      color="primary50"
                      weight="semibold"
                      align="center"
                    >
                      Check back later to see results
                    </Text>
                  </Padding>
                )}

                {obpIndex < data.data.length && (
                  <div
                    style={{
                      padding: theme.spacing.x2,
                      background: theme.colors.grey15,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => setObpIndex(data.data.length)}
                  >
                    <Text
                      align="right"
                      size="x5"
                      weight="medium"
                      color="secondary100"
                    >
                      See More
                    </Text>
                  </div>
                )}
              </TableContainer>
            </Container>
          </OutterWrapper>
          <Spacer height="x4" />
          <AdvertContainer>
            <Advert
              src={require("../assets/ehoaPod.png")}
              onClick={() =>
                window.open(
                  "https://traffic.libsyn.com/secure/eventingpodcast/EHOA_1_Mixdown_3.mp3"
                )
              }
            />
            <Spacer height="x4" />
            <Advert
              src={require("../assets/ehoa1.png")}
              onClick={() => window.open("https://ehoa.org/")}
            />
            <Spacer height="x4" />
            <Advert
              src={require("../assets/ehoa2.png")}
              onClick={() => window.open("https://ehoa.org/contact-us/")}
            />
            <Spacer height="x4" />
          </AdvertContainer>
        </Wrapper>
      </>
    )
    : null;
};

export default EHOALeague;
