import React from "react";
import styled from "styled-components";

import { theme } from "../config";
import Text from "./Text";
import HStack from "./HStack";
import Spacer from "./Spacer";

const Container = styled.table`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-top: ${theme.spacing.x4};
  flex-direction: column;
  @media (${theme.breakpoints.mediumAndUp}) {
    margin-top: 0;
  }
`;

const TableContainer = styled.div`
  border: 1px solid transparent;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: "2px 2px 10px rgba(0, 0, 0, 1)";
`;

const ListItemContainer = styled.tr`
  box-sizing: border-box;
  width: 100%;
  padding: ${theme.spacing.x2} ${theme.spacing.x2};
  background: ${({ idx }) =>
    idx % 2 === 0 ? theme.colors.white100 : theme.colors.grey15};
  &:hover {
    background: ${({ isHeader }) =>
      isHeader ? theme.colors.grey15 : theme.colors.secondary20};
  }
`;

const Column = styled.td`
  display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "block")};
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
  @media (${theme.breakpoints.mediumAndUp}) {
    display: block;
  }
`;

const Header = styled.th`
  display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "block")};
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
  font-size: ${theme.spacing.x4};
  color: ${theme.colors.primary100};
  text-align: ${({ align }) => (align ? align : "left")};
  font-weight: ${theme.fontWeight.bold};
  @media (${theme.breakpoints.mediumAndUp}) {
    font-size: ${theme.spacing.x5};
    display: block;
  }
`;

const ListItem = ({ item, idx, isAllTime }) => {
  return (
    <ListItemContainer idx={idx}>
      <Spacer height="x2" />
      <HStack align="center" justify="space-between">
        <Column width={"2%"}>
          <Text
            color="grey100"
            size="x3"
            weight="bold"
          >{`${item.attributes.rank}.`}</Text>
        </Column>
        <Column width={"5%"}>
          <img
            src={`https://data.fei.org/Content/Images/Flags/${item.attributes.horseNf}.png`}
            alt={`Flag: ${item.attributes.horseNf}`}
          />
        </Column>
        <Column width={"25%"}>
          <Text color="grey100" size="x3" mobileSize="x2" weight="semibold">
            {item.attributes.horse === "CHIPMUNK FRH"
              ? "FISCHERCHIPMUNK FRH"
              : item.attributes.horse}
          </Text>
        </Column>
        <Column width={"25%"} hideOnMobile={true}>
          <Text color="grey100" size="x3" weight="semibold">
            {item.attributes.athlete}
          </Text>
        </Column>
        <Column width={"10%"}>
          <Text
            color="grey100"
            size="x4"
            mobileSize="x3"
            weight="bold"
            align="right"
          >
            {item.attributes.eloOut}
          </Text>
        </Column>
        <Column width={"8%"} hideOnMobile={isAllTime ? false : true}>
          {isAllTime ? (
            <Text
              color="grey100"
              size="x4"
              mobileSize="x3"
              weight="bold"
              align="right"
            >
              {item.attributes.date.slice(0, 4)}
            </Text>
          ) : (
            <Text
              color={
                item.attributes.eloOut - item.attributes.eloIn > 0
                  ? "success"
                  : "error"
              }
              size="x2"
              weight="bold"
              align="right"
            >
              {item.attributes.eloOut - item.attributes.eloIn > 0
                ? `+${item.attributes.eloOut - item.attributes.eloIn}`
                : item.attributes.eloOut - item.attributes.eloIn}
            </Text>
          )}
        </Column>
      </HStack>
      <Spacer height="x2" />
    </ListItemContainer>
  );
};

const EloTable = ({ title, list, isAllTime }) => {
  return (
    <Container>
      <Spacer height="x1" />
      <TableContainer>
        <ListItemContainer isHeader={true}>
          <HStack align="center" justify="space-between">
            <Header width="2%"> </Header>
            <Header width="5%">Nation</Header>
            <Header width="25%">Horse</Header>
            <Header width="25%" hideOnMobile={true}>
              Athlete
            </Header>
            <Header width="10%" align="right">
              Horse Elo
            </Header>
            {isAllTime ? (
              <Header width="8%" align="right" hideOnMobile={false}>
                Year
              </Header>
            ) : (
              <Header width="8%" align="right" hideOnMobile={true}>
                Change since last comp
              </Header>
            )}
          </HStack>
        </ListItemContainer>
        {list.map((item, idx) => {
          return (
            <ListItem key={idx} item={item} idx={idx} isAllTime={isAllTime} />
          );
        })}
      </TableContainer>
    </Container>
  );
};

export default EloTable;
