import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { RegionalPwrAvgTopTen, Spacer, Text, } from "../components";
import { theme, envVars } from "../config";
import { useResource } from "../hooks";

const ListsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: wrap;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
  }
`;

const RegionalPwrAvgSummary = ({ region }) => {
    const title = envVars.powerAverageRegionTitles[region.toLowerCase()] || "Regional";

    const [isLoadingBe80, setIsLoadingBe80] = useState(true);
    const [isLoadingBe90, setIsLoadingBe90] = useState(true);
    const [isLoadingBe100, setIsLoadingBe100] = useState(true);
    const [isLoadingNovice, setIsLoadingNovice] = useState(true);

    let baseApiUrl = `provider/${envVars.providerId}/youth_regional_power_average` +
        `?athlete_region=${region}` +
        `&return_top=10` +
        `&one_in_region_required=false` +
        `&athlete_max_age=18`

    const { getAll: getTopTenRecordsBe80, all: topTenBe80 } = useResource(
        baseApiUrl +
        `${envVars.powerAverageClassLevelParams["be80"]["dateFromParam"]}` +
        `${envVars.powerAverageClassLevelParams["be80"]["dateToParam"]}` +
        `${envVars.powerAverageClassLevelParams["be80"]["classLevelParam"]}`
    );

    useEffect(() => {
        const fetchData = async () => {
            setIsLoadingBe80(true);
            await getTopTenRecordsBe80();
            setIsLoadingBe80(false);
        };

        fetchData();
    }, [getTopTenRecordsBe80]);

    const { getAll: getTopTenRecordsBe90, all: topTenBe90 } = useResource(
        baseApiUrl +
        `${envVars.powerAverageClassLevelParams["be90"]["dateFromParam"]}` +
        `${envVars.powerAverageClassLevelParams["be90"]["dateToParam"]}` +
        `${envVars.powerAverageClassLevelParams["be90"]["classLevelParam"]}`
    );

    useEffect(() => {
        const fetchData = async () => {
            setIsLoadingBe90(true);
            await getTopTenRecordsBe90();
            setIsLoadingBe90(false);
        };

        fetchData();
    }, [getTopTenRecordsBe90]);

    const { getAll: getTopTenRecordsBe100, all: topTenBe100 } = useResource(
        baseApiUrl +
        `${envVars.powerAverageClassLevelParams["be100"]["dateFromParam"]}` +
        `${envVars.powerAverageClassLevelParams["be100"]["dateToParam"]}` +
        `${envVars.powerAverageClassLevelParams["be100"]["classLevelParam"]}`
    );

    useEffect(() => {
        const fetchData = async () => {
            setIsLoadingBe100(true);
            await getTopTenRecordsBe100();
            setIsLoadingBe100(false);
        };

        fetchData();
    }, [getTopTenRecordsBe100]);

    const { getAll: getTopTenRecordsNovice, all: topTenNovice } = useResource(
        baseApiUrl +
        `${envVars.powerAverageClassLevelParams["novice"]["dateFromParam"]}` +
        `${envVars.powerAverageClassLevelParams["novice"]["dateToParam"]}` +
        `${envVars.powerAverageClassLevelParams["novice"]["classLevelParam"]}`
    );

    useEffect(() => {
        const fetchData = async () => {
            setIsLoadingNovice(true);
            await getTopTenRecordsNovice();
            setIsLoadingNovice(false);
        };

        fetchData();
    }, [getTopTenRecordsNovice]);

    return (
        <>
            <Text size="x6" weight="semibold">
                {title} Youth Leagues
            </Text>
            <Spacer height="x8" />
            <ListsContainer>
                <RegionalPwrAvgTopTen
                    title="BE80"
                    list={topTenBe80}
                    region={region}
                    isLoading={isLoadingBe80}
                />
                <Spacer width="x4" />
                <RegionalPwrAvgTopTen
                    title="BE90"
                    list={topTenBe90}
                    region={region}
                    isLoading={isLoadingBe90}
                />
                <Spacer width="x4" />
            </ListsContainer>
            <Spacer height="x8" />
            <ListsContainer>
                <RegionalPwrAvgTopTen
                    title="BE100"
                    list={topTenBe100}
                    region={region}
                    isLoading={isLoadingBe100}
                />
                <Spacer width="x4" />
                <RegionalPwrAvgTopTen
                    title="Novice"
                    list={topTenNovice}
                    region={region}
                    isLoading={isLoadingNovice}
                />
                <Spacer width="x4" />
            </ListsContainer>
        </>
    );
};

export default RegionalPwrAvgSummary;
