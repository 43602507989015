import React from "react";
import styled from "styled-components";
import { Spacer, Text, VStack } from "../components";
import { theme } from "../config";
import { navigate } from "@reach/router";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-image: url("https://res.cloudinary.com/britisheventing/image/upload/v1653573298/images/2022-05/howden%20background.png");
`;

const NavContainer = styled.div`
  width: 90%;
  height: 100%;
  padding: ${theme.spacing.x4};
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 50%;
    height: 75%;
  }
`;

const Button = styled.div`
  padding: ${theme.spacing.x2};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border: 2px solid ${theme.colors.grey15};
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
  margin-left: ${theme.spacing.x2};
  margin-right: ${theme.spacing.x2};
  :hover {
    transform: scale(1.05);
  }
  margin-bottom: ${theme.spacing.x4};
  @media (${theme.breakpoints.mediumAndUp}) {
    width: auto;
    flex-direction: row;
  }
`;

const MobileHStack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  flex-grow: ${({ grow }) => grow};
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
  }
`;

const HowdenWayPrelim = ({ setActiveRoute }) => {
  return (
    <Container>
      <NavContainer>
        <VStack align="center">
          <Spacer height="x8" />
          <Text
            color="white80"
            size="x6"
            weight="semibold"
            align="center"
            mobileSize="x5"
          >
            Welcome to
          </Text>
          <Text
            color="white100"
            size="x7"
            weight="bold"
            align="center"
            mobileSize="x6"
          >
            The Howden Way Young Horse Leagues
          </Text>
        </VStack>
        <Spacer height="x8" />
        <MobileHStack align="center" justify="space-between">
          <Button
            onClick={() => {
              navigate("/5yo_power_average");
            }}
          >
            <VStack align="center" justify="center">
              {/* <Image
                src={require("../assets/equilume.png")}
                style={{ alignSelf: "center", margin: "0 auto" }}
              /> */}
              <Text
                color="white100"
                size="x5"
                weight="semibold"
                align="center"
                mobileSize="x5"
              >
                5 Year Old
              </Text>
            </VStack>
          </Button>
          <Button
            onClick={() => {
              navigate("/6yo_power_average");
            }}
          >
            <VStack align="center" justify="center">
              <Text
                color="white100"
                size="x5"
                weight="semibold"
                align="center"
                mobileSize="x5"
              >
                6 Year Old
              </Text>
            </VStack>
          </Button>
          <Button
            onClick={() => {
              navigate("/7yo_power_average");
            }}
          >
            <VStack align="center" justify="center">
              <Text
                color="white100"
                size="x5"
                weight="semibold"
                align="center"
                mobileSize="x5"
              >
                7 Year Old
              </Text>
            </VStack>
          </Button>
        </MobileHStack>
      </NavContainer>
    </Container>
  );
};

export default HowdenWayPrelim;
