import React from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";

import { theme } from "../config";
import Text from "./Text";
import VStack from "./VStack";
import HStack from "./HStack";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-top: ${theme.spacing.x4};
  flex-direction: column;
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 49.5%;
    margin-top: 0;
  }
`;

const Divider = styled.div`
  height: 3px;
  width: 100%;
  background: ${theme.colors.primary100};
  @media (${theme.breakpoints.mediumAndUp}) {
    min-height: 4px;
  }
`;

const ListItemContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: ${theme.spacing.x2} ${theme.spacing.x2};
  background: ${({ idx }) =>
        idx % 2 === 0 ? theme.colors.white100 : theme.colors.grey20};
`;

const Column = styled.div`
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
`;

const ListItem = ({ item, idx }) => {
    item = item.attributes;

    return (
        <ListItemContainer idx={idx}>
            <HStack align="center" justify="space-between">
                <HStack align="center">
                    <Column width={"10%"}>
                        <Text color="grey100" size="x2" weight="bold">
                            {`${item.powerAverageRank}.`}
                        </Text>
                    </Column>
                    <Column width={"10%"}>
                        <img
                            src={`https://data.fei.org/Content/Images/Flags/${item.nf}.png`}
                            alt={`Flag: ${item.nf}`}
                        />
                    </Column>
                    <Column width={"60%"}>
                        <Text color="grey100" size="x2" weight="semibold">
                            {item.horse}
                        </Text>
                    </Column>
                </HStack>
                <Column width={"20%"}>
                    <Text color="grey100" size="x3" weight="bold" align="right">
                        {item.powerAverage}
                    </Text>
                </Column>
            </HStack>
        </ListItemContainer>
    );
};

const RegionalPwrAvgTopTen = ({
    title,
    list,
    region,
    showSeeMore = true,
    isLoading,
}) => {
    return (
        <Container>
            <VStack>
                <Text size="x5" weight="semibold">
                    {title}
                </Text>
                <Divider />
                {isLoading
                    ? (
                        <ListItemContainer idx="0">
                            <Text size="x4" weight="semibold">
                                Loading...
                            </Text>
                        </ListItemContainer>
                    )
                    : (
                        list && list.data.length > 0
                            ? list.data.map((item, idx) => {
                                return <ListItem key={idx} item={item} idx={idx} />;
                            })
                            : <ListItemContainer idx="0">
                                <Text size="x4" weight="semibold">
                                    No qualifiers. Check back later.
                                </Text>
                            </ListItemContainer>
                    )
                }
            </VStack>
            {showSeeMore && !isLoading && list && list.data.length > 0 && (
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/youth_leagues/${region}/${title.toLowerCase()}`)}
                >
                    <HStack justify="flex-end">
                        <Text size="x4" weight="semibold">
                            See More
                        </Text>
                    </HStack>
                </div>
            )}
        </Container>
    );
};

export default RegionalPwrAvgTopTen;
