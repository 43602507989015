import styled from "styled-components";

const HStack = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  flex-grow: ${({ grow }) => grow};
`;
HStack.defaultProps = {
  align: "flex-start",
  justify: "flex-start",
  grow: 1,
};

export default HStack;
